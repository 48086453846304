import { Offre } from "@src/consts";
import { STEPS, STEP_NAMES } from "@src/Steps/Steps";
import { Dispatch, SetStateAction, useEffect } from "react";
import { StateProjet } from "@src/App";
import {
  browserNavigateBackward,
  browserNavigateForward,
  browserNavigateForwardReset,
  browserNavigateReplace,
} from "./browserNavigation";
import { NavigationDirection } from "./useStepNavigation";

export function getInitialActiveStep(
  initialProjet: StateProjet | null,
  selectedOffre: Offre | null,
  projetHasError?: boolean,
  unhandledError?: boolean
): StepName {
  if (unhandledError) return STEP_NAMES.ERREUR_INCONNUE;
  if (projetHasError) return STEP_NAMES.ERREUR_RECUPERATION_PROJET;
  if (initialProjet?.duplication?.start)
    return initialProjet?.duplication?.start;
  if (
    !initialProjet?.dossierEnAttente &&
    !initialProjet?.signatureEnAttente &&
    !initialProjet?.souscriptionEnAttente
  ) {
    switch (selectedOffre) {
      case Offre.AGENT_TERRITORIAL:
      case Offre.FRONTALIER:
      case Offre.STANDARD:
        return STEP_NAMES.QUI_ASSURER;
      case Offre.TNS:
        return STEP_NAMES.VOUS;
      default:
        return STEP_NAMES.FUNNEL1;
    }
  }

  if (initialProjet.signatureEnAttente) return STEP_NAMES.PLUS_QU_A_SIGNER;
  if (initialProjet.dossierEnAttente) return STEP_NAMES.COMPLETER_VOTRE_DOSSIER;
  return STEP_NAMES.RECAP_SOUSCRIPTION;
}

export function useNavigateToInitialStepOnMount(initialStepName: StepName) {
  useEffect(() => {
    browserNavigateReplace(STEPS[initialStepName].path);
  }, []);
}

export function getChangeStepFn(
  history: StepName[],
  isTransitioning: boolean,
  setIsTransitioning: Dispatch<SetStateAction<boolean>>
) {
  return (nextStep: StepName) => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    const isForward = !history.find((entry) => entry === nextStep);
    if (isForward) {
      if (!STEPS[nextStep].hasPrevious) {
        browserNavigateForwardReset(STEPS[nextStep].path);
        return;
      }
      browserNavigateForward(STEPS[nextStep].path);
      return;
    }
    browserNavigateBackward(nextStep, history);
  };
}

export function getGoToPrevStepFn(
  history: StepName[],
  changeStep: (nextStep: StepName) => void
) {
  return () => changeStep(history[history.length - 2]);
}

export function getNavigationDirection(
  history: StepName[],
  nextStepName: StepName
): NavigationDirection {
  return history.find((entry) => entry === nextStepName)
    ? "backward"
    : "forward";
}

export function getNextHistory(
  history: StepName[],
  nextStepName: StepName,
  navigationDirection: NavigationDirection
) {
  if (navigationDirection === "forward") {
    if (!STEPS[nextStepName].hasPrevious) {
      return [nextStepName];
    }
    return [...history, nextStepName];
  }
  const previousStepIndex = history.findIndex(
    (entry) => entry === nextStepName
  );

  return history.slice(0, previousStepIndex + 1);
}

export function findDivergingIndex(a: unknown[], b: unknown[]) {
  const l = Math.min(a.length, b.length);
  for (let i = 0; i < l; i += 1) {
    if (a[i] !== b[i]) return i;
  }
  return -1;
}

export function getNextStepsData(
  history: StepName[],
  dataHistory: StepName[],
  stepsData: StepsData,
  nextStepName: StepName
) {
  const divergingIndex = findDivergingIndex(history, dataHistory);
  if (divergingIndex === -1) {
    if (dataHistory.find((entry) => entry === nextStepName)) {
      return {
        nextDataHistory: dataHistory,
        nextStepsData: stepsData,
      };
    }
    return {
      nextDataHistory: [...dataHistory, nextStepName],
      nextStepsData: stepsData,
    };
  }

  return {
    nextDataHistory: [...dataHistory.slice(0, divergingIndex), nextStepName],
    nextStepsData: dataHistory.slice(divergingIndex).reduce(
      (datas, entry) => {
        delete datas[entry];
        return datas;
      },
      { ...stepsData }
    ),
  };
}
