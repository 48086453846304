import React from "react";

const QuandAdhererTNSStep = React.lazy(() => import("./QuandAdhererTNS.step"));
const VotreEntrepriseTNSStep = React.lazy(
  () => import("./VotreEntrepriseTNS.step")
);

export const STEPS_DATE_SOUSCRIPTION_TNS = {
  QUAND_ADHERER_TNS: {
    hasPrevious: true,
    progress: 85,
    path: "/quand-adherer-tns",
    component: QuandAdhererTNSStep,
  },
  VOTRE_ENTREPRISE: {
    hasPrevious: true,
    progress: 86,
    path: "/votre-entreprise",
    component: VotreEntrepriseTNSStep,
  },
};
