import { ReactElement, useEffect, useMemo, useState } from "react";

import "@lya-protect/lya-protect-form-library/dist/style.css";
import "./app.scss";
import { Toaster } from "@lya-protect/lya-protect-form-library/dist/UI";
import * as Sentry from "@sentry/react";
import { TopBar } from "./TopBar/TopBar";
import { Stepper } from "./Stepper/Stepper";
import { Offre } from "./consts";
import { LoaderBlock } from "./Loader/LoaderBlock";
import { gatewayAPI } from "./GatewayAPI";
import { STEP_NAMES } from "./Steps/Steps";

export type StateProjet = {
  duplication?: {
    start: StepName;
    selectedOffre: Offre | null;
  };
} & GetProjetResponse;

const ALLOWED_DUPLICATION_START = [STEP_NAMES.VOUS, STEP_NAMES.QUI_ASSURER];
type AllowedDuplicationStart = (typeof ALLOWED_DUPLICATION_START)[number];
function useInitialDataFromLocation(): {
  idProjet: string | null;
  pathname: string;
  duplicationStart: AllowedDuplicationStart | undefined;
} {
  return useMemo(() => {
    let idProjet: string | null = null;
    let duplicationStart: AllowedDuplicationStart | undefined;

    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      idProjet = params.get("id");
      const paramDuplicationStart = params.get("duplication-start");
      if (
        paramDuplicationStart &&
        ALLOWED_DUPLICATION_START.includes(
          paramDuplicationStart as AllowedDuplicationStart
        )
      )
        duplicationStart = paramDuplicationStart as AllowedDuplicationStart;
    }
    return { idProjet, pathname: window.location.pathname, duplicationStart };
  }, []);
}

function transformGatewayProject(
  projetGateway: GetProjetResponse
): GetProjetResponse {
  return {
    dossierEnAttente: projetGateway.dossierEnAttente,
    signatureEnAttente: projetGateway.signatureEnAttente,
    souscriptionEnAttente: projetGateway.souscriptionEnAttente,
  };
}

function useProjet(
  id: string | null,
  duplicationStart: StepName | undefined
): [
  StateProjet,
  boolean,
  <T extends keyof GetProjetResponse>(
    state: T,
    data: GetProjetResponse[T]
  ) => void,
  boolean,
  GetProjetResponse | null
] {
  const [projet, setProjet] = useState<StateProjet>({
    duplication: duplicationStart
      ? {
          start: duplicationStart,
          selectedOffre: null,
        }
      : undefined,
    dossierEnAttente: undefined,
    signatureEnAttente: undefined,
    souscriptionEnAttente: undefined,
  });
  const [duplicatedData, setDuplicatedData] =
    useState<GetProjetResponse | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isFetching, setIsFetching] = useState(id !== null);
  useMemo(() => {
    if (!id) return;

    gatewayAPI.axios
      .get<GetProjetResponse>(`getProjet/${id}`)
      .then((res) => {
        setIsFetching(false);
        if (duplicationStart) {
          setProjet(() => ({
            duplication: {
              start: duplicationStart,
              selectedOffre: res.data.souscriptionEnAttente?.offre || null,
              dateEmbauche: res.data.dateEmbauche,
              collectivite: res.data.collectivite,
              dateCreationEntreprise: res.data.dateCreationEntreprise,
            },
            dossierEnAttente: undefined,
            signatureEnAttente: undefined,
            souscriptionEnAttente: undefined,
          }));
          setDuplicatedData(res.data);
        } else {
          setProjet(() => ({
            duplication: undefined,
            ...transformGatewayProject(res.data),
          }));
        }
      })
      .catch(() => {
        setHasError(true);
        setIsFetching(false);
      });
  }, []);

  function setProjetData<T extends keyof GetProjetResponse>(
    state: T,
    data: GetProjetResponse[T]
  ) {
    setProjet((prevProjet) => ({
      duplication: prevProjet.duplication,
      dossierEnAttente: prevProjet?.dossierEnAttente,
      signatureEnAttente: prevProjet?.signatureEnAttente,
      souscriptionEnAttente: prevProjet?.souscriptionEnAttente,
      [state]: data,
    }));
  }

  return [projet, isFetching, setProjetData, hasError, duplicatedData];
}

function getInitialSelectedOffre(pathname: string): Offre | null {
  switch (pathname) {
    case "/agent-territorial":
    case "/agent-territorial/":
      return Offre.AGENT_TERRITORIAL;
    case "/tns":
    case "/tns/":
      return Offre.TNS;
    case "/frontalier":
    case "/frontalier/":
      return Offre.FRONTALIER;
    case "/standard":
    case "/standard/":
      return Offre.STANDARD;
    case "/":
    case "/funnel":
    case "/funnel/":
      return null;
    default:
      return sessionStorage.getItem("selectedOffre") as Offre | null;
  }
}

function useSelectedOffre(
  isFetchingProjet: boolean,
  projet: StateProjet | null,
  pathname: string
): [Offre | null, (offre: Offre | null) => void, boolean] {
  const [selectedOffre, _setSelectedOffre] = useState<Offre | null>(null);
  const [isFetchingOffre, setIsFetchingOffre] = useState(true);

  const setSelectedOffre = (offre: Offre | null) => {
    if (offre === null) {
      sessionStorage.removeItem("selectedOffre");
    } else {
      sessionStorage.setItem("selectedOffre", offre);
    }
    _setSelectedOffre(offre);
    if (isFetchingOffre) setIsFetchingOffre(false);
  };

  useEffect(() => {
    if (!isFetchingProjet) {
      if (projet?.duplication?.selectedOffre) {
        setSelectedOffre(projet.duplication.selectedOffre);
      } else if (projet?.souscriptionEnAttente?.offre) {
        setSelectedOffre(projet?.souscriptionEnAttente?.offre);
      } else {
        setSelectedOffre(getInitialSelectedOffre(pathname));
      }
    }
  }, [isFetchingProjet, projet?.souscriptionEnAttente?.offre]);

  return [selectedOffre, setSelectedOffre, isFetchingOffre];
}

export function App(): ReactElement {
  const { idProjet, duplicationStart, pathname } = useInitialDataFromLocation();
  const [projet, isFetching, setProjetData, hasError, duplicatedData] =
    useProjet(idProjet, duplicationStart);
  const [selectedOffre, setSelectedOffre, isFetchingOffre] = useSelectedOffre(
    isFetching,
    projet,
    pathname
  );
  return (
    <>
      <div className="app">
        <TopBar />
        <LoaderBlock isLoading={isFetching || isFetchingOffre} />
        <Sentry.ErrorBoundary
          fallback={
            <Stepper
              projet={{}}
              duplicatedData={null}
              unhandledError
              setProjetData={() => undefined}
              setSelectedOffre={() => undefined}
              selectedOffre={null}
            />
          }
        >
          {isFetching || isFetchingOffre ? null : (
            <Stepper
              projet={projet}
              duplicatedData={duplicatedData}
              projetHasError={hasError}
              setProjetData={setProjetData}
              setSelectedOffre={setSelectedOffre}
              selectedOffre={selectedOffre}
            />
          )}
        </Sentry.ErrorBoundary>
      </div>
      <Toaster />
    </>
  );
}
