import {
  Button,
  useIsMobile,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Offre } from "@src/consts";
import { ProgressBar } from "@src/ProgressBar/ProgressBar";
import { ReducedToggleContent } from "@src/SidePane/ReducedToggleContent";
import { SidePane, SidePaneHandle } from "@src/SidePane/SidePane";
import { ToggleContent } from "@src/SidePane/ToggleContent";
import { STEPS } from "@src/Steps/Steps";
import classNames from "classnames";
import React, { Suspense, useRef } from "react";
import { StateProjet } from "@src/App";
import { LoaderBlock } from "../Loader/LoaderBlock";
import { PreviousButton } from "./PreviousButton/PreviousButton";
import "./stepper.scss";
import { useStepNavigation } from "./useStepNavigation/useStepNavigation";

const ContactPane = React.lazy(() => import("@src/SidePane/ContactPane"));

type StepperProps = {
  duplicatedData: GetProjetResponse | null;
  projet: StateProjet;
  selectedOffre: Offre | null;
  setSelectedOffre: (offre: Offre | null) => void;
  setProjetData: <T extends keyof GetProjetResponse>(
    state: T,
    data: GetProjetResponse[T]
  ) => void;
  projetHasError?: boolean;
  unhandledError?: boolean;
};

export function Stepper({
  projet,
  setProjetData,
  selectedOffre,
  setSelectedOffre,
  projetHasError,
  unhandledError,
  duplicatedData,
}: StepperProps) {
  const {
    activeStep,
    changeStep,
    goBackAfterError,
    history,
    transition,
    stepsData,
    updateActiveStepData,
    isLoading,
    setIsLoading,
    isTransitioning,
  } = useStepNavigation(projet, selectedOffre, projetHasError, unhandledError);
  const isMobile = useIsMobile();
  const sidePaneRef = useRef<SidePaneHandle>(null);
  const ActiveStepComponent = activeStep.component;

  const getProgressStep = (step: Step) => {
    if (selectedOffre === Offre.TNS) {
      if (step.component === STEPS.VOUS.component) {
        return 5;
      }
      if (step.component === STEPS.QUI_ASSURER.component) {
        return 6;
      }
    }

    return activeStep.progress;
  };

  return (
    <>
      <div className="stepper">
        <LoaderBlock isLoading={isLoading} />
        <div
          className={classNames(
            "stepper__header",
            (activeStep as { headerClassName?: string }).headerClassName
          )}
          data-large={!!(activeStep as { largeContent?: boolean }).largeContent}
        >
          <ProgressBar
            progress={getProgressStep(activeStep)}
            hidden={isLoading}
          />
          <div
            className="stepper__previous-button-wrapper"
            id="previous-button-wrapper"
          />
        </div>
        <Suspense fallback={<LoaderBlock isLoading isTransparent />}>
          <div className="stepper__container">
            <div className="stepper__step" data-transition={transition}>
              <div
                className="stepper__step-content"
                data-large={
                  !!(activeStep as { largeContent?: boolean }).largeContent
                }
              >
                <PreviousButton
                  history={history}
                  changeStep={changeStep}
                  hidden={isTransitioning || isLoading}
                  disabled={isTransitioning || isLoading}
                />
                <ActiveStepComponent
                  duplicatedData={duplicatedData}
                  selectedOffre={selectedOffre}
                  setProjetData={setProjetData}
                  projet={projet}
                  setSelectedOffre={setSelectedOffre}
                  goBackAfterError={goBackAfterError}
                  changeStep={changeStep}
                  stepsData={stepsData}
                  setIsLoading={setIsLoading}
                  setStepData={updateActiveStepData}
                />
              </div>
              {activeStep !== STEPS.MENTIONS_LEGALES && (
                <div
                  className="stepper__mentions-legales-wrapper"
                  data-large={
                    !!(activeStep as { largeContent?: boolean }).largeContent
                  }
                >
                  <div>
                    <Button
                      variant="text"
                      onClick={() => changeStep("MENTIONS_LEGALES")}
                    >
                      Mentions légales
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Suspense>
      </div>
      <SidePane
        ref={sidePaneRef}
        hideToggle={isLoading}
        reduceToggle={
          isMobile || !!(activeStep as { largeContent?: boolean }).largeContent
        }
        content={
          <Suspense fallback={null}>
            <ContactPane
              togglePane={(isOpened: boolean) => {
                if (sidePaneRef.current) {
                  sidePaneRef.current.toggle(isOpened);
                }
              }}
            />
          </Suspense>
        }
        toggleContent={<ToggleContent />}
        reducedToggleContent={<ReducedToggleContent />}
      />
    </>
  );
}
